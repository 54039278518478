import React from 'react'
import DeviceStatus from '../components/DeviceStatus'
import { Container } from './styles'
import * as T from './types'

const App = ({ properties, ...props }: T.AppPropsType) => {
  return (
    <Container>
      <DeviceStatus
        properties={properties}
        {...props}
      />
    </Container>
  )
}

export default App
