import {
  Stack,
  DeviceCacheApiClient,
  UserMgtSvcClientv3,
  UserTenantMgtSvcClient
} from '@jarvis/web-stratus-client'
import { getProps } from './commonMethods'
import { JarvisWebHttpClient } from '@jarvis/web-http'

let useOrgedAuthProvider: boolean

export const getDevicesDetails = async (authStack, authProvider) => {
  const urlEnv = (() => {
    switch (authStack) {
      case Stack.prod:
        return ''
      case Stack.stage:
        return 'stage-'
      default:
        return 'pie-'
    }
  })()

  useOrgedAuthProvider = getProps()?.properties?.useOrgedAuthProvider

  const baseAuthProvider = useOrgedAuthProvider
    ? authProvider.createOrgedAuthProvider()
    : authProvider

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/devicecache/v1/devices`

  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: baseAuthProvider,
    defaultBaseURL: baseUrl
  })
  const responseTotal = await httpClient.head({})
  return responseTotal
}

export const isServiceCustomer = async () => {
  const props = getProps()
  const criterion = await props.criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceCustomer.READ',
    options: {
      userContext: 'organization'
    }
  })
  return criterion
}

export const getOfflineDevicesDetails = async (
  count: number,
  authStack,
  authProvider
) => {
  useOrgedAuthProvider = getProps()?.properties?.useOrgedAuthProvider

  const baseAuthProvider = useOrgedAuthProvider
    ? authProvider.createOrgedAuthProvider()
    : authProvider

  const client = new DeviceCacheApiClient(authStack, baseAuthProvider)
  const response = await client.list({
    params: {
      connectionState: 'offline',
      limit: 50,
      offset: count
    }
  })
  return response
}

export const getEmail = async (authStack, authProvider) => {
  const userMgtClient = new UserMgtSvcClientv3(authStack, authProvider)
  const activeUser = await userMgtClient.getCurrentActiveUser()
  const data = activeUser.data
  return data
}

export const getTenantDetails = async (authStack, authProvider) => {
  const userMgtClient = new UserTenantMgtSvcClient(authStack, authProvider)
  const activeUser = await userMgtClient.getCurrentActiveUser()
  const data = activeUser.data
  return data
}
