import React from 'react'
import DeviceOfflineWidget from './deviceofflinewidget'
import * as S from './styles'
import { setProps } from '../../utils/commonMethods'

export type CheckOptions = {
  type: string
  value: string
  options: {
    userContext: string
  }
}

export type DeviceStatusProps = {
  country?: string
  language?: string
  hideLoader?: boolean
  totalOfflineDevicesMockCount?: number
  mockNumberOfDevices?: number
  criterion?: {
    check: (options: CheckOptions) => boolean
  }
  properties?: {
    useOrgedAuthProvider?: boolean
  }
  enablePrinterStrings?: string
  enableSSOLink?: string
  mockNavigation?: {
    push: (url: string) => void
  }
}

export type CombinedMfePropsType = {
  spacingRight: number
} & DeviceStatusProps

export const DeviceStatus = (props: DeviceStatusProps) => {
  setProps(props)

  const spacingRight = props.enablePrinterStrings ? -55 : -20

  const mfeProps: CombinedMfePropsType = { ...props, spacingRight }

  return (
    <S.DeviceWrapper
      customHeight={props.enablePrinterStrings ? 'auto' : undefined}
    >
      <DeviceOfflineWidget
        {...mfeProps}
        data-testid="device-offline-widget-component"
      />
    </S.DeviceWrapper>
  )
}

export default DeviceStatus
