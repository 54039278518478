import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Card, ProgressIndicator } from '@veneer/core'

const defaultBorderBottom = '1px solid #2121211a'

export const TotalOfflineDeviceCount = styled.span`
  font-size: ${primitives.typography.size4};
  line-height: 1.2;
  margin-right: auto;
  color: #737373;
  padding: 0 ${primitives.layout.size1}px;
`
export const MainWrapper = styled.div<{ customWrapperHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ customWrapperHeight }) =>
    customWrapperHeight ? '324px' : '100%'};
`
export const LabelContainer = styled.div<{ customBorder?: boolean }>`
  display: flex;
  align-items: flex-start;
  height: 56px;
  border-bottom: ${({ customBorder }) =>
    customBorder ? '' : defaultBorderBottom};
`
export const Header = styled.div`
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight4};
  margin: 14px 0 14px ${primitives.layout.size5}px;
  padding-right: ${primitives.layout.size5}px;
`
export const NoOfflineDevicesWrapper = styled.div<{ customHeight?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ customHeight }) => customHeight};
  flex-direction: column;
  svg {
    background: rgba(33, 33, 33, 0.1);
    border-radius: 50%;
    height: 36px;
    width: 36px;
    padding: 6px;
    margin-bottom: ${primitives.layout.size2}px;
  }
`
export const NoOfflineDevices = styled.div`
  color: #404040;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
`
export const Offlinewrapper = styled.div<{
  customOfflineWrapPadding?: boolean
}>`
  width: 100% !important;
  padding: ${({ customOfflineWrapPadding }) =>
    customOfflineWrapPadding ? '5px 0 0 0' : '17px 0'};
`
export const chartgauge = styled.div`
  margin-left: 14px;
  margin-right: 10px;
`
export const StyledLinearGauge = styled.div`
  margin: auto;
  margin-top: ${primitives.layout.size1}px;
  width: 100% !important;
  svg[class='highcharts-root'] {
    text[text-anchor='start'] {
      width: 75px;
      height: 20px;
      font-family: ${primitives.typography.family0};
      line-height: ${primitives.typography.lineHeight2} !important;
      color: #212121 !important;
      fill: #212121 !important;
      font-style: normal;
      font-weight: 400;
      font-size: ${primitives.typography.size2};
    }
  }
  div[class='highcharts-container '] {
    width: 100% !important;
  }
  rect[class='highcharts-background'] {
    width: 100% !important;
  }
  rect[class='highcharts-plot-background'] {
    width: 100% !important;
  }
  div[class='highcharts-data-labels highcharts-series-1 highcharts-bullet-series'] {
    span {
      height: 20px !important;
      width: 150px !important;
      top: -9px !important;
      font-family: ${primitives.typography.family0} !important;
      font-style: normal;
      font-weight: 400;
      font-size: ${primitives.typography.size2} !important;
      line-height: ${primitives.typography.lineHeight2} !important;
      text-align: right;
      cursor: default;
      right: 15px !important;
      color: #212121;
    }
  }
  svg[class='highcharts-root'] {
    g[class='highcharts-series-group'] {
      g[class='highcharts-series highcharts-series-0 highcharts-bullet-series'] {
        rect[class='highcharts-point'] {
          fill: #dbdbdb;
          border-radius: ${primitives.layout.cornerRadius2}px;
          border: 1px solid black;
          box-sizing: border-box;
        }
      }
      g[class='highcharts-series highcharts-series-1 highcharts-bullet-series'] {
        rect[class='highcharts-point'] {
          fill: #737373;
        }
      }
    }
  }
`

export const DeviceWrapper = styled.div<{ customHeight?: string }>`
  min-width: 0;
  height: ${({ customHeight }) => customHeight || '360px !important'};
  background-color: #fff;
  border-radius: ${primitives.layout.cornerRadius4}px;
`
export const LoaderCard = styled(Card)<{ customHeight?: string }>`
  width: 100%;
  height: ${({ customHeight }) => customHeight || '360px'};
  vertical-align: middle;
`
export const StyledLoader = styled(ProgressIndicator)<{
  customMargin?: string
}>`
  margin-top: ${({ customMargin }) => customMargin || '165px'};
  div {
    margin: auto;
  }
`

export const Footer = styled.div`
  height: 52px;
  width: 100%;
  border-top: 1px solid rgba(33, 33, 33, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
