import React from 'react'
import { IconWarningAlt } from '@veneer/core'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import * as S from './styles'

type NoOfflineDevicesProps = {
  enablePrinterStrings: string
}

const NoOfflineDevices = (props: NoOfflineDevicesProps) => {
  const enablePrinterStrings = props.enablePrinterStrings
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  return (
    <S.NoOfflineDevicesWrapper
      customHeight={enablePrinterStrings ? '324px' : '217px'}
    >
      <IconWarningAlt />
      <S.NoOfflineDevices>
        {t(
          'ecp-device-status-widget.noDevicesFound',
          'No offline devices found'
        )}
      </S.NoOfflineDevices>
    </S.NoOfflineDevicesWrapper>
  )
}

export default NoOfflineDevices
