import { Dispatch, SetStateAction } from 'react'
import { DeviceStatusProps } from '../components/DeviceStatus/index'

let localprops: DeviceStatusProps = {
  language: 'en',
  country: 'US',
  criterion: null,
  properties: {
    useOrgedAuthProvider: null
  },
  enablePrinterStrings: null
}

export const setProps = (props: DeviceStatusProps) => {
  localprops = {
    ...localprops,
    ...props
  }
}

export const getProps = (): DeviceStatusProps => {
  return localprops
}

export const handleOnClick = (navigation, enableSSOLink) => {
  if (enableSSOLink) {
    return window.open(enableSSOLink)
  } else {
    return navigation.push('/devices?connectionState=offline')
  }
}

export const setCustomBoolean = (props: {
  enablePrinterStrings: string | undefined
}) => {
  const { enablePrinterStrings } = props
  if (enablePrinterStrings) {
    return true
  } else {
    return false
  }
}

export const mapOfflineDevicesData = (props: {
  offlineDevices: {
    data: { status: { connectionStateLastUpdatedAt: string } }[]
  }
  setLessThan7Days: Dispatch<SetStateAction<number>>
  setDays7: Dispatch<SetStateAction<number>>
  setDays14: Dispatch<SetStateAction<number>>
  setDays30: Dispatch<SetStateAction<number>>
  mockNumberOfDevices: number
}) => {
  const currentDate = Date()
  let lessThan7days = 0
  let days7 = 0
  let days14 = 0
  let days30 = 0
  const {
    offlineDevices,
    setLessThan7Days,
    setDays7,
    setDays14,
    setDays30,
    mockNumberOfDevices
  } = props
  offlineDevices?.data?.forEach(
    (device: { status: { connectionStateLastUpdatedAt: string } }) => {
      const givenDate = device?.status?.connectionStateLastUpdatedAt
      const days =
        (new Date(currentDate).getTime() - new Date(givenDate).getTime()) /
        (1000 * 3600 * 24)
      if (givenDate != null) {
        if (days < 7) {
          lessThan7days++
        } else if (days >= 7 && days <= 14) {
          days7++
        } else if (days > 14 && days <= 30) {
          days14++
        } else if (days > 30) {
          days30++
        } else {
          // do nothing
        }
      }
    }
  )
  setLessThan7Days(mockNumberOfDevices || lessThan7days)
  setDays7(mockNumberOfDevices || days7)
  setDays14(mockNumberOfDevices || days14)
  setDays30(mockNumberOfDevices || days30)
}
