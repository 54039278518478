import {
  EVENT_ACTION_BUTTON_CLICKED,
  EVENT_ACTIVITY,
  EVENT_CATEGORY,
  EVENT_CONTROL_VIEW_DETAILS_BUTTON_CLICKED,
  EVENT_DETAIL_TYPE,
  EVENT_DETAIL_VERSION,
  EVENT_SCREEN_NAME,
  EVENT_SCREEN_PATH,
  EVENT_VERSION
} from './constants'

const activity = EVENT_ACTIVITY
const eventDetailVersion = EVENT_DETAIL_VERSION
const screenPath = EVENT_SCREEN_PATH

export const publishEvent = (analytics, eventDetail) => {
  const publishCdmEvents = analytics.publishCdmEvents

  if (!publishCdmEvents) {
    return
  }

  eventDetail.activity = activity

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: EVENT_DETAIL_TYPE,
      eventCategory: EVENT_CATEGORY,
      eventDetail,
      version: EVENT_VERSION
    }
  ])
}

// Events

export const VeiwDetailsButtonClicked = (activeUser: ActiveUserType) => {
  return {
    action: EVENT_ACTION_BUTTON_CLICKED,
    controlDetail: `Fullname: ${activeUser.fullName}, UserResourceId: ${activeUser.resourceId}, RoleCategory: ${activeUser.roleCategory}, TenantResourceId: ${activeUser.tenantResourceId}, TenantType: ${activeUser.tenantType}, ClickedAt: ${new Date().toISOString()}`,
    controlName: EVENT_CONTROL_VIEW_DETAILS_BUTTON_CLICKED,
    screenName: EVENT_SCREEN_NAME,
    screenPath,
    version: eventDetailVersion
  }
}

// Interfaces

export type ActiveUserType = {
  fullName: string
  resourceId: string
  roleCategory: string
  tenantResourceId: string
  tenantType: string
}

export type AnalyticsEventPropType = {
  action: string
  activity: string
  screenPath: string
  screenName: string
  screenMode: string
  controlName: string
  controlDetail: string
  version: string
}
