// Shell analytics

export const EVENT_SCREEN_PATH = '/EcpDashboard/'
export const EVENT_ACTIVITY = 'DeviceStatusWidget-v01'
export const EVENT_DETAIL_TYPE =
  'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1'
export const EVENT_CATEGORY = 'simpleUi'
export const EVENT_DETAIL_VERSION = '1.0.0'
export const EVENT_VERSION = '1.0.0'
export const EVENT_ACTION_BUTTON_CLICKED = 'ControlButtonClicked'
export const EVENT_SCREEN_NAME = 'Dashboard'
export const EVENT_CONTROL_VIEW_DETAILS_BUTTON_CLICKED =
  'ViewDetailsButtonClicked'
