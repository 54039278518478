import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Chart,
  ChartProvider,
  IconChevronRight
} from '@veneer/core'
import highchartsInterface, {
  ChartOptionInterface
} from '@veneer/highcharts-interface'
import {
  getDevicesDetails,
  getOfflineDevicesDetails,
  getEmail,
  getTenantDetails
} from '../../utils/api'
import {
  setProps,
  getProps,
  handleOnClick,
  setCustomBoolean,
  mapOfflineDevicesData
} from '../../utils/commonMethods'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import { isServiceCustomer } from '../../../src/utils/api'
import {
  ActiveUserType,
  VeiwDetailsButtonClicked,
  publishEvent
} from '../../../src/utils/analytics'
import { CombinedMfePropsType } from '.'
import NoOfflineDevices from './NoOfflineDevices'
import * as S from './styles'

const DeviceOfflineWidget = (props: CombinedMfePropsType) => {
  setProps(props)

  const enablePrinterStrings = props.enablePrinterStrings
  const enableSSOLink = props.enableSSOLink
  const spacingRight = props.spacingRight
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation || props.mockNavigation
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  const orgedAuthProvider = authProvider?.createOrgedAuthProvider?.()
  const analytics = interfaces?.v1?.analytics || {}

  const [showButton, setShowButton] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [, setTotalDevices] = useState<number>(0)
  const [totalOfflineDevices, setTotalOfflineDevices] = useState<number>(0)
  const [lessThan7days, setLessThan7Days] = useState<number>(0)
  const [days7, setDays7] = useState<number>(0)
  const [days14, setDays14] = useState<number>(0)
  const [days30, setDays30] = useState<number>(0)

  const xTotalCount = 'x-total-count'

  let totalDevicesFetched = 0
  let count = 0

  const [isLoading, setIsLoading] = useState<boolean>(
    props.hideLoader ? false : true
  )

  const useOrgedAuthProvider = getProps().properties?.useOrgedAuthProvider

  const getDeviceCount = async () => {
    const response = await getDevicesDetails(authStack, authProvider)
    setTotalDevices(
      props.mockNumberOfDevices || parseInt(response?.headers?.[xTotalCount])
    )
    return response
  }

  const getOfflineTotalDevices = async () => {
    const { mockNumberOfDevices } = props
    const offlineDevices = await getOfflineDevicesDetails(
      count,
      authStack,
      authProvider
    )
    totalDevicesFetched += offlineDevices?.data?.length
    const offlineDevicescount = parseInt(offlineDevices?.headers?.[xTotalCount])
    mapOfflineDevicesData({
      offlineDevices,
      setLessThan7Days,
      setDays7,
      setDays14,
      setDays30,
      mockNumberOfDevices
    })
    if (totalDevicesFetched < offlineDevicescount) {
      count++
      getOfflineTotalDevices()
    } else {
      setTotalOfflineDevices(
        props.totalOfflineDevicesMockCount
          ? props.totalOfflineDevicesMockCount
          : parseInt(offlineDevices?.headers?.[xTotalCount])
      )
      if (offlineDevicescount === 0) {
        setIsDisabled(true)
      }
      setIsLoading(false)
    }
  }

  const getServiceCustomer = async () => {
    const response = await isServiceCustomer()
    setIsDisabled(useOrgedAuthProvider && !response)
    return response
  }

  useEffect(() => {
    setShowButton(true)
    getServiceCustomer()
    getDeviceCount()
    getOfflineTotalDevices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = async () => {
    if (!enableSSOLink) {
      const activeUser: ActiveUserType = {
        fullName: '',
        resourceId: '',
        roleCategory: '',
        tenantResourceId: '',
        tenantType: ''
      }
      const getActiveUserDetails = async () => {
        await getEmail(authStack, orgedAuthProvider).then((result) => {
          activeUser.fullName = `${result.givenName} ${result.familyName}`
          activeUser.resourceId = result.resourceId
        })
        await getTenantDetails(authStack, orgedAuthProvider).then((result) => {
          activeUser.roleCategory = result.roleCategory
          activeUser.tenantResourceId = result.tenantResourceId
          activeUser.tenantType = result.tenantType
        })
      }
      await getActiveUserDetails()
      publishEvent?.(analytics, VeiwDetailsButtonClicked(activeUser))
    }
    return handleOnClick(navigation, enableSSOLink)
  }

  const labelStyle = {
    style: {
      width: 100,
      textOverflow: 'none',
      whiteSpace: 'nowrap'
    }
  }

  const options1: ChartOptionInterface = {
    chart: {
      type: 'lineargauge',
      height: 50,
      spacingRight: spacingRight,
      style: {
        width: 100
      }
    },
    xAxis: {
      labels: labelStyle,
      categories: [
        t('ecp-device-status-widget.OfflineLessThan7days', '< 7 days')
      ]
    },
    series: [
      {
        type: 'bullet',
        data: [
          {
            y: lessThan7days,
            target: totalOfflineDevices
          }
        ],
        dataLabels: {
          enabled: true,
          format: `${lessThan7days} / ${totalOfflineDevices}`,
          style: {
            width: 100,
            whiteSpace: 'nowrap',
            border: '0'
          }
        }
      }
    ]
  }

  const options2: ChartOptionInterface = {
    chart: {
      height: 50,
      type: 'lineargauge',
      spacingRight: spacingRight,
      style: {
        width: 100
      }
    },
    xAxis: {
      labels: labelStyle,
      categories: [
        t('ecp-device-status-widget.Offline7to14Days', '7 - 14 days')
      ]
    },
    series: [
      {
        type: 'bullet',
        data: [
          {
            y: days7,
            target: totalOfflineDevices
          }
        ],
        dataLabels: {
          enabled: true,
          format: `${days7} / ${totalOfflineDevices}`,
          style: {
            width: 100,
            whiteSpace: 'nowrap',
            border: '0'
          }
        }
      }
    ]
  }

  const options3: ChartOptionInterface = {
    chart: {
      height: 50,
      type: 'lineargauge',
      spacingRight: spacingRight,
      style: {
        width: 100
      }
    },
    xAxis: {
      labels: labelStyle,
      categories: [
        t('ecp-device-status-widget.Offline15to30Days', '15 - 30 days')
      ]
    },
    series: [
      {
        type: 'bullet',
        data: [
          {
            y: days14,
            target: totalOfflineDevices
          }
        ],
        dataLabels: {
          enabled: true,
          format: `${days14} / ${totalOfflineDevices}`,
          style: {
            width: 100,
            whiteSpace: 'nowrap'
          }
        }
      }
    ]
  }

  const options4: ChartOptionInterface = {
    chart: {
      height: 50,
      type: 'lineargauge',
      spacingRight: spacingRight,
      style: {
        width: 100
      }
    },
    xAxis: {
      labels: labelStyle,
      categories: [
        t('ecp-device-status-widget.OfflineMoreThan30Days', '> 30 days')
      ]
    },

    series: [
      {
        type: 'bullet',
        data: [
          {
            y: days30,
            target: totalOfflineDevices
          }
        ],
        dataLabels: {
          enabled: true,
          format: `${days30} / ${totalOfflineDevices}`,
          style: {
            width: 100,
            whiteSpace: 'nowrap'
          }
        }
      }
    ]
  }

  const getContent = (
    <>
      {isLoading ? (
        <S.LoaderCard content={<S.StyledLoader />} />
      ) : (
        <S.MainWrapper
          customWrapperHeight={setCustomBoolean({ enablePrinterStrings })}
        >
          <S.LabelContainer
            customBorder={setCustomBoolean({ enablePrinterStrings })}
            data-testid="device-offline-label-container-component"
          >
            <S.Header data-testid="device-offline-header-component">
              {!enablePrinterStrings
                ? t('ecp-device-status-widget.title', 'Devices Offline')
                : enablePrinterStrings}
              <S.TotalOfflineDeviceCount>
                {totalOfflineDevices > 0 && !enablePrinterStrings
                  ? `(${totalOfflineDevices})`
                  : ''}
              </S.TotalOfflineDeviceCount>
            </S.Header>
          </S.LabelContainer>
          <S.Offlinewrapper
            customOfflineWrapPadding={setCustomBoolean({
              enablePrinterStrings
            })}
          >
            {totalOfflineDevices > 0 ? (
              <ChartProvider
                data-testid="device-offline-chart-provider-component"
                chartInterface={highchartsInterface}
              >
                <S.chartgauge data-testid="device-offline-chart-gauge-main-component">
                  <S.StyledLinearGauge data-testid="device-offline-first-gauge-component">
                    <Chart options={options1} />
                  </S.StyledLinearGauge>
                  <S.StyledLinearGauge data-testid="device-offline-second-gauge-component">
                    <Chart options={options2} />
                  </S.StyledLinearGauge>
                  <S.StyledLinearGauge data-testid="device-offline-third-gauge-component">
                    <Chart options={options3} />
                  </S.StyledLinearGauge>
                  <S.StyledLinearGauge data-testid="device-offline-fourth-gauge-component">
                    <Chart options={options4} />
                  </S.StyledLinearGauge>
                </S.chartgauge>
              </ChartProvider>
            ) : (
              <NoOfflineDevices enablePrinterStrings={enablePrinterStrings} />
            )}
          </S.Offlinewrapper>
          {showButton && (
            <S.Footer>
              <Button
                className="detailsbutton"
                trailingIcon={<IconChevronRight />}
                data-testid="view-details-button"
                appearance="ghost"
                onClick={onClick}
                disabled={isDisabled}
              >
                {t(
                  'ecp-device-status-widget.viewDetailsButton',
                  'View Details'
                )}
              </Button>
            </S.Footer>
          )}
        </S.MainWrapper>
      )}
    </>
  )

  return (
    <Card
      data-testid="device-offline-get-content-component"
      content={getContent}
    />
  )
}

export default DeviceOfflineWidget
